<style scoped>
table.inshadow > tbody {
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-moz-box-shadow: -0x 0px 6px 0px rgba(0,0,0,0.6) inset;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
tr {
  /* cursor: pointer; */
  background-color: unset;
}
th {
  border-bottom: 1px solid #343a40;
}
td.actions:hover {
  opacity: 1 !important;
}
.btn:disabled {
  pointer-events: none;
}
@media screen and (max-width: 767px){
  .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
      white-space: unset;
  }
}
.media {
  padding-left: 0px;
}
a.disabled {
  pointer-events: none;
}
</style>

<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 py-3">
          <button
            class="btn btn-default btn-sm float-right"
            :disabled="loading"
            @click.prevent="refresh"
          >
            <Icon icon="ic:outline-refresh" inline="true" width="1em" />
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table table-hover inshadow">
            <tbody>
              <tr v-if="downloads.length<1">
                <td colspan="2" class="text-center">
                  {{ stringError ? stringError : "No hay descargas aquí..." }}
                </td>
              </tr>
              <tr v-for="(it, idx) in downloads" :key="idx">
                <td width="5%" class="align-middle">
                  <div class="form-group">
                    <div class="checkbox ml-2">
                      <input
                        :id="`checkbox_${idx}`"
                        type="checkbox"
                        :checked="it.checked"
                        @change.prevent="it.checked = $event.target.checked"
                      >
                      <label class="pl-0 mb-0" :for="`checkbox_${idx}`">&nbsp;</label>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="media-list media-list-divided">
                    <div class="media">
                      <div class="media-body">
                        <p v-if="it.status=='ready'">
                          <a
                            class="hover-info"
                            style="word-break: break-all;"
                            target="_blank"
                            :class="{disabled: downloading}"
                            :href="`${API_ROOT}/api/devices/videos/${tincIp}/${it.filename}`"
                            @click.prevent="downloadVideo(tincIp, it.filename)"
                          >
                            <div
                              v-if="downloading === it.filename"
                              class="spinner-border spinner-border-sm"
                              style="width: 0.9rem; height: 0.9rem;"
                              role="status"
                            >
                              <span class="sr-only">Descargando...</span>
                            </div>
                            <Icon v-else icon="ic:outline-cloud-download" inline="true" width="1.2em" />
                            {{ it.filename }} <br>
                            <time class="text-dark">
                              {{ `Fecha: ${formatDate(it.datetime)} Duración: ${formatDuration(it.duration)}` }}
                            </time>
                            <!-- <time v-if="downloading === it.filename" class="text-primary d-block">
                              <div class="spinner-border spinner-border-sm" style="width: 0.8rem; height: 0.8rem;" role="status">
                                <span class="sr-only">Descargando...</span>
                              </div>
                              Descargando...
                            </time> -->
                          </a>
                        </p>
                        <p v-else-if="it.status=='error'" class="text-dark" title="No se han encontrado archivos">
                          <Icon class="text-danger" icon="ic:sharp-error-outline" inline="true" width="1.2em" />
                          {{ it.filename }} <br>
                          <time class="text-dark">
                            {{ `Fecha: ${formatDate(it.datetime)} Duración: ${formatDuration(it.duration)}` }}
                          </time> <br>
                          <time class="text-dark hover-danger">
                            Error: No se han encontrado archivos.
                          </time>
                        </p>
                        <p v-else class="text-dark" title="Archivo en espera...">
                          <Icon icon="ic:baseline-hourglass-top" inline="true" width="1.2em" />
                          {{ it.filename }} <br>
                          <time class="text-dark">
                            {{ `Fecha: ${formatDate(it.datetime)} Duración: ${formatDuration(it.duration)}` }}
                          </time>
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pb-0">
          <button
            v-if="downloads.length>0"
            class="btn btn-danger btn-sm float-right"
            :disabled="loading || !isSomeSelected"
            @click.prevent="deleteDownloads"
          >
            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <Icon v-show="!loading" icon="ic:baseline-delete" inline="true" width="1em" />
            {{ loading ? "Eliminar descargas...":"Eliminar descargas" }}
          </button>
          <button
            v-if="downloads.length>0"
            class="btn btn-default btn-sm float-left"
            :disabled="loading"
            @click.prevent="selectAllDownloads"
          >
            <Icon v-if="!selectAll" icon="ic:baseline-check" inline="true" width="1em" />
            <Icon v-if="selectAll" icon="ic:outline-square" inline="true" width="1em" />
            {{ selectAll ? "Deseleccionar todos":"Seleccionar todos" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import {
  format, parseISO
} from "date-fns";
import { es } from "date-fns/locale";
import { Icon } from "@iconify/vue2";

import ServiceApi from "./Devices.service";

export default {
  name: "DownloadsTable",
  components: {
    Icon
  },
  props: {
    item: {
      type: Object,
      default() {
        return {
          _id: null,
          hwuid: "",
          name: "",
          tinchost:"",
          tincip: ""
        };
      }
    }
  },
  data() {
    return {
      downloads: [],
      loading: false,
      stringError: "",
      selectAll: false,
      downloading: null
    };
  },
  computed: {
    ...mapState(["API_ROOT"]),
    isSomeSelected() {
      return this.downloads.some((it) => it.checked);
    },
    tincIp() {
      const { tincip = "" } = this.item ?? {};
      return tincip;
    }
  },
  watch: {
    item: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, old) {
        if (!val) {
          return;
        }
        this.downloads = [];
        this.fillInitial();
      }
    }
  },
  methods: {
    fillInitial() {
      this.stringError = "";
      if (!this.tincIp) {
        this.stringError = "tincip is required";
        return;
      }
      this.loading = true;
      ServiceApi.getDownloadList(this.tincIp, {})
        .then(res => {
          this.loading = false;
          const { rows = [] } = res;
          this.downloads = [];
          this.selectAll = false;
          console.log("RES:", res);
          this.$nextTick(() => {
            this.downloads = rows.map(it => ({ ...it, checked: false }));
            this.$emit("totalChanged", this.downloads.length);
          });
        })
        .catch(err => {
          this.loading = false;
          const { response = {} } = err;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = err } = dataResponse;
          this.stringError = `${errStr}, CODE: ${status}`;
          if (this.downloads.length > 0) {
            this.showAlert({
              title: "Cola de descargas",
              msg: this.stringError,
              icon: "error"
            });
          }
        });
    },
    refresh() {
      this.$nextTick(() => {
        this.fillInitial();
      });
    },
    formatDate(isodate) {
      if (!isodate) {
        return "-";
      }
      try {
        const dt = parseISO(isodate);
        return format(dt, "dd/MM/yyyy HH:mm:ss", { locale: es });
      }
      catch (e) {
        return "-";
      }
    },
    formatDuration(duration) {
      if (Number.isNaN(duration) || !duration) {
        return "-";
      }
      const date = new Date(0);
      date.setSeconds(duration);
      return date.toISOString().slice(11, 19);
    },
    downloadVideo(ip, name) {
      console.log("DOWNLOAD:", ip, name);
      this.downloading = name;
      const instance = axios.create({
        baseURL: "",
        headers: {}
      });
      instance.get(`${this.API_ROOT}/api/devices/videos/${ip}/${name}`, { responseType: "blob", withCredentials: true })
        .then(res => {
          this.downloading = null;
          const bloburl = (window.URL || window.webkitURL).createObjectURL( new Blob([res.data], { type: "video/mp4" }) );
          const a = document.createElement("a");
          a.href = bloburl;
          a.download = name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }).catch(err => {
          this.downloading = null;
          const { response = {}, message = "" } = err;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          console.log(status, errstr);
          this.showAlert({
            icon: "error",
            title: "No se pudo descargar",
            msg: `${errstr}, CODE: ${status}`
          });
        });
    },
    deleteDownloads() {
      this.loading = true;
      const downloadsSel = this.downloads.filter(it => {
        const { checked = false } = it;
        return checked;
      }).map(it => it.hash);
      console.log(downloadsSel);
      this.$swal({
        title: "Eliminar descargas",
        html: `Seleccionadas: <b>${downloadsSel.length}</b><br>¿Desea eliminar las descargas?`,
        icon: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
        confirmButtonColor: "#f96868",
        cancelButtonColor: "",
      // eslint-disable-next-line consistent-return
      }).then((result) => {
        this.loading = false;
        if (result.isConfirmed) {
          ServiceApi.removeDownloadList(this.tincIp, downloadsSel)
            .then(res => {
              this.loading = false;
              console.log("DELETE RES:", res);
              this.refresh();
            })
            .catch(err => {
              this.loading = false;
              const { response = {} } = err;
              const { data: dataResponse = {}, status = 400 } = response;
              const { error: errStr = err } = dataResponse;
              if (this.downloads.length > 0) {
                this.showAlert({
                  title: "No se pudo eliminar descargas",
                  msg: `${errStr}, CODE: ${status}`,
                  icon: "error"
                });
              }
            });
        }
      });
    },
    selectAllDownloads() {
      this.selectAll = !this.selectAll;
      let checked = false;
      if (this.selectAll) {
        checked = true;
      }
      this.downloads = this.downloads.map(it => ({ ...it, checked }));
    },
    showAlert(msg) {
      const {
        title = "", msg:html = "", icon = "", timer = 4000
      } = msg ?? {};
      this.$swal({
        title,
        html,
        icon,
        timer,
        position: "bottom-end",
        showConfirmButton: false,
        showCancelButton: false,
        timerProgressBar: true,
        toast: true
      });
    }
  },
  mounted() {}
};
</script>
